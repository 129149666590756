import { Component } from '@angular/core';
import { ApiService } from '../../services/server.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss'
})
export class HistoryComponent {

  historyData: any;
  pageData: any = { page: 1, itemsPerPage: 10, totalCount: 0 }

  constructor(private callApi: ApiService) { }

  ngOnInit() {
    this.getHistory({}, 1)
  }

  getHistory(payload: any, page: any) {
    this.callApi.getTransactions(payload, page).subscribe((res: any) => {
      this.historyData = res?.data?.dataSent
      this.pageData.page = page
      this.pageData.totalCount = res?.data?.totalCount
    })
  }

  handleChange(event:any){
    this.getHistory({},event)
  }

}
