import { Component } from '@angular/core';
import { ApiService } from '../../services/server.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  loginForm: any;
  showPassword:boolean=false;

  constructor(private callApi: ApiService, private fb: FormBuilder,private router:Router) { }

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email,]],
      password: ['', [Validators.required]]
    })
  }

  get f() {
    return this.loginForm.controls
  }

  submitLoginData(){
    this.callApi.showSpinner()
    this.callApi.login(this.loginForm.value).subscribe(async (res:any)=>{
      if(await res?.success){
        try { sessionStorage.setItem('flashtoken',res?.data?.token) } catch (error) { }
        this.callApi.showSuccess(res?.message)
        await this.callApi.getUserToken()
        this.router.navigate([''])
      } else {
        this.callApi.showError(res?.message)
      }
      this.callApi.hideSpinner()
    },err =>{
      this.callApi.hideSpinner()
      this.callApi.showInfo('Try again later!')
    })
  }

  change(){
    this.showPassword = !this.showPassword
  }

}
