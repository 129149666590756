<section class="header-padding">
    <div class="container">
        <div class="header-banner-bg">
            <h4 class="text-white">Ethereum Market</h4>
            <div class="d-flex mt-5 align-items-center">
                <div class="amount-details ">
                    <p class="mb-1">Net worth</p>
                    <h3>$ 40</h3>
                </div>
                <div class="amount-details ms-5">
                    <p class="mb-1">Net APY</p>
                    <h3>-</h3>
                </div>
            </div>
            <div class="text-end">
                <div class="button-primary">
                    View Transactions
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12">

                <div class="box-bg mt-4">
                    <h4 class="text-white">Transactions</h4>
                    <div class="input-group mb-3 w-50 mt-4">
                        <input type="text" class="form-control" placeholder="Recipient's username"
                            aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <span class="input-group-text" id="basic-addon2">Search</span>
                    </div>
                    <div class="mt-4">
                        <table class="table text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Time</th>
                                    <th scope="col">Trx Hash</th>
                                    <th scope="col">From</th>
                                    <th scope="col">TO</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Profit Amount</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for(data of historyData|paginate: { itemsPerPage: pageData?.itemsPerPage,
                                currentPage:pageData?.page, totalItems: pageData?.totalCount };track data){
                                <tr>
                                    <td>{{data?.createdAt|date:'MMM d, y, h:mm a'}}</td>
                                    <td>
                                        <a [href]="data?.hashLink" target="_blank" [ngClass]="data?.status?'text-success fw-bolder':'text-danger fw-bolder'" class="text-decoration-none">
                                            {{(data?.transactionHash|slice:0:5)+'...'+(data?.transactionHash|slice:-5)}}
                                        </a>
                                    </td>
                                    <td>{{(data?.from|slice:0:5)+'...'+(data?.from|slice:-5)}}</td>
                                    <td>{{(data?.to|slice:0:5)+'...'+(data?.to|slice:-5)}}</td>
                                    <td>{{data?.amount}}</td>
                                    <td>{{data?.profitTokenAmount}}</td>
                                    <td [ngClass]="data?.status?'text-success fw-bolder':'text-danger fw-bolder'">
                                        {{data?.status?'Success':'Failed'}}
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                        <div class="text-end">
                            <pagination-controls (pageChange)="handleChange($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>