<ngx-spinner type="ball-clip-rotate-pulse"></ngx-spinner>
<section>
    <div class="container">
        <div class="box-bg mt-4 d-flex justify-content-between">
            <div>
                <div class="d-flex">
                    <div>
                        <p class="mb-2 text-color-1">Network</p>
                        <h6 class="mb-0">Ethereum</h6>
                    </div>
                    <div class="ms-5">
                        <p class="mb-2 text-color-1">Address</p>
                        <h6 class="mb-0">0x4AE1...57C5</h6>
                    </div>
                </div>
               
            </div>
            <div>
                <h4 class="mb-0 text-color-2">0.00 ETH</h4>
            </div>
        </div>
        <div class="loan-details mt-4 bg-light p-3 rounded-2 shadow">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <h5 class="">Loan Amount: (ETH)</h5>
                    <!-- <span class="label-text mb-2">Min 25, Max 10000 ETH</span>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"> -->
                    <div>
                        <ul class="list-unstyled loan-details-list">  
                            <!-- <li><span>Dapp Fee</span> <span>0.3%</span></li> -->
                            <li><span>Swap Fee</span> <span>0.1{{selectedCoinData?.srcSymbol}}</span></li>
                            <li><span>Loan Fee</span> <span>0.05</span></li>
                            <li><span>Total Gas Fee</span> <span>0.15 {{selectedCoinData?.srcSymbol}}</span></li>
                            <li><span>Routing</span> <span>{{priceRoutesData?.transParam?.partner}}</span></li>
                            <li><span>Src Token</span> <span>{{selectedCoinData?.srcSymbol||'-'}}</span></li>
                            <li><span>Dest Token</span> <span>{{selectedCoinData?.symbol||'-'}}</span></li>
                            <li>
                                <span>Estimted profit from Arbitrage</span> 
                                <span [ngClass]="estimatedProfit>0?'text-success':'text-danger'">
                                    {{estimatedProfit?(estimatedProfit|number:'0.0-2')+'%':'-'}}
                                </span>
                            </li>
                            <!-- <li><span>ROi</span> <span class="text-color-2">x27.17</span></li> -->
                        </ul>
                        
                    </div>
                </div>
                <div class="col-lg-5">
                    <!-- <span>Finder ID</span>
                    <h6>Qidfjd</h6> -->
                    <!-- <div class="mt-2">
                        <button class="button-primary w-100">Deposit ETH</button>
                    </div> -->
                    <div class="mt-2">
                        <button class="button-primary w-100" (click)="submitCoinData()">Execute FlashBot Arbitrage</button>
                    </div>
                   
                </div>
            </div>
            
              
        </div>
    </div>
</section>