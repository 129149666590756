<ngx-spinner type="ball-clip-rotate-pulse"></ngx-spinner>
<section>
    <div class="container">
        <div class="box-bg mt-4 d-flex justify-content-between">
            <div>
                <div class="d-flex">
                    <div>
                        <p class="mb-2 text-color-1">Network</p>
                        <h6 class="mb-0">Ethereum</h6>
                    </div>
                    <div class="ms-5">
                        <p class="mb-2 text-color-1">Address</p>
                        <h6 class="mb-0">0x4AE1...57C5</h6>
                    </div>
                </div>

            </div>
            <div>
                <h4 class="mb-0 text-color-2">0.00 ETH</h4>
            </div>
        </div>
       
        <div class="row justify-content-between mt-4" >
            <div class="col-md-3">
                <select class="form-select" aria-label="Default select example" [value]="chainId" (change)="netWorkChange($event)">
                    <option value="1">Ethereum</option>
                    <option value="56">BNB</option>
                    <option value="137">Matic</option>
                  </select>
            </div>
            <div class="col-lg-3 col-md-5 mt-4 mt-md-0">
                <div class="text-end">
                    <button class="button-primary" (click)="generateOpportunities()">Generate Opportunities</button>
                </div>
            </div>
        </div>
        
        <div class="loan-details mt-4 bg-light p-3 rounded-2 shadow table-responsive">
            <table class="w-100 text-center table">
                <thead>
                    <tr>
                        <th>Swap Fee</th>
                        <th>Loan Fee</th>
                        <th>Total Gas Fee</th>
                        <th>Profit</th>
                        <th>Src Token</th>
                        <th>Dest Token</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    @for(data of opportunities;track data){
                    <tr>
                        <td class="table-content">0.1{{data?.srcTokenSlug}}</td>
                        <td class="table-content">0.05</td>
                        <td class="table-content">0.15{{data?.srcTokenSlug}}</td>
                        <td class="table-content" [ngClass]="data?.profit > 0?'text-success':'text-danger'">{{data?.profit?(data?.profit|number: '0.0-2')+'%':''}}</td>
                        <td class="table-content">{{data?.srcTokenSlug}}</td>
                        <td class="table-content">{{data?.destTokenSlug}}</td>
                        <td>
                            <button class="button-primary" (click)="swapData(data)">Swap</button>
                        </td>
                    </tr>
                    }@empty{
                    <tr>
                        <td class="table-content" colspan="12">No opportunities found</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</section>