<nav class="nav-bg">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-4">
                <img src="assets/images/logo.png" alt="" routerLink="/" class="img-fluid cursor-pointer">
            </div>
            <div class="col-lg-8 text-end">
                <div class="float-end">
                    <ul class="list-unstyled d-flex align-items-center mb-0">
                        <!-- <li routerLink="/welcome">Loan Details</li> -->
                        <li class="ms-3" *ngIf="flashaddress">
                            <div class="button-primary mt-2 mt-md-0" data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop">
                                Switch Token
                            </div>
                            <div class="button-primary ms-2 mt-2 mt-md-0" routerLink="opportunity">
                                Opportunities
                            </div>
                            <div class="button-primary ms-2 mt-2 mt-md-0" (click)="startStopCron(allData?.cronStatus)">
                                {{allData?.cronStatus ? 'Stop Cron':'Start Cron'}}
                                @if(allData?.cronStatus == true || allData?.cronStatus ==false){
                                    <span class="svg-border">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" [ngClass]="allData?.cronStatus?'text-success bg':'text-danger'" fill="currentColor" class="bi bi-octagon-fill" viewBox="0 0 16 16">
                                          <path d="M11.107 0a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146A.5.5 0 0 1 4.893 0z"/>
                                        </svg>
                                    </span>
                                } @else {
                                    <span>...</span>
                                }
                            </div>
                            <div class="button-primary ms-2 mt-2 mt-md-0" routerLink="history">
                                History
                            </div>
                            <div class="dropdown button-primary ms-2 mt-2 mt-md-0">
                                <button class="btn btn-secondary dropdown-toggle bg-transparent border-0 p-0 fs-14" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{(flashaddress|slice:0:5)+'...'+(flashaddress|slice:-5)}}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" (click)="logout()">LogOut</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="ms-3" *ngIf="!flashaddress">
                            <div class="button-primary" (click)="connect()">
                                Connect Wallet
                            </div>
                            <div class="button-primary ms-2" (click)="logout()" >
                                Logout
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>

<!-- popup -->

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Switch Network</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="position-relative mb-2">
                        <!-- <input type="text" class="form-control form-div switch-input" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1" [value]="selectedCoin.shortAddress"> -->
                        <form class="example-form d-flex">
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Select Token" aria-label="State" [matAutocomplete]="auto"
                                    [formControl]="stateCtrl" class="form-control form-div switch-input">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let state of filteredStates | async" [value]="state.address"
                                        (onSelectionChange)="selectCoin(state)">
                                        <img style="vertical-align:middle;" aria-hidden
                                            src="{{state.img? state.img:''}}" height="25" />
                                        <span class="ms-2">{{ state.symbol }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <span *ngIf="selectedCoin?.symbol" class="m-4">{{selectedCoin?.symbol}}</span>
                        </form>
                        <div>
                            <input type="tel" class="form-control form-div switch-input" placeholder="0.00"
                                name="txamount" [(ngModel)]="selectedCoin.amount">
                        </div>
                        <!-- <div class="coin-selecting-grp">
                        <div class="coin-selecting">
                            <h6 *ngIf="!selectedCoin?.symbol" class="mt-2">Select Token</h6>
                            <h6 *ngIf="selectedCoin?.symbol"><img [src]="selectedCoin?.img" class="img-fluid me-2" width="32" alt="">{{selectedCoin?.symbol}}</h6>
                            <div class="coin-selecting-list">
                                <ul class="list-unstyled mb-0 ps-0">
                                    <li *ngFor="let data of tokenList" (click)="selectCoin(data)"><img [src]="data?.img" class="img-fluid me-2" width="32" alt="">{{data?.symbol}}</li>
                                </ul>
                            </div>
                        </div>
                    </div> -->

                    </div>
                    <!-- <div class="text-center">
                    <img src="assets/images/swap.png" alt="" width="50" class="swap-icon">
                </div>
                <div class="mt-4 position-relative">
                    <input type="text" class="form-control form-div switch-input" placeholder="0.00" aria-label="Username" aria-describedby="basic-addon1">
                    <div class="coin-selecting-grp">
                        <div class="coin-selecting">
                            <h6><img src="assets/images/usdc.png" class="img-fluid me-2" width="32" alt="">USDC</h6>
                            <div class="coin-selecting-list">
                                <ul class="list-unstyled mb-0 ps-0">
                                    <li><img src="assets/images/btc.png" class="img-fluid me-2" width="32" alt="">BTC</li>
                                    <li><img src="assets/images/usdc.png" class="img-fluid me-2" width="32" alt="">USDC</li>
                                    <li><img src="assets/images/usdt.png" class="img-fluid me-2" width="32" alt="">USDT</li>
                                    <li><img src="assets/images/polygon.png" class="img-fluid me-2" width="32" alt="">Polygon</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->
                    <div *ngIf="selectedCoin?.amount > 0">
                        <button *ngIf="selectedCoin" class="button-primary mt-2 w-100"
                            (click)="submit()">Switch</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #networkDialog>
    <div class="network-dialog">
        <div class="d-flex justify-content-between p-2">
            <p class="mt-1">Choose Network To Swap</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red" class="bi bi-x"
                viewBox="0 0 16 16" (click)="close()">
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
        </div>
        <ul class="">
            <li class="">
                <input id="eth" type="radio" name="network" (click)="chooseNetwork('1')">
                <label for="eth">Ethereum</label>
            </li>
            <li>
                <input id="bnb" type="radio" name="network" (click)="chooseNetwork('56')">
                <label for="bnb">BNB</label>
            </li>
            <li>
                <input id="poly" type="radio" name="network" (click)="chooseNetwork('137')">
                <label for="poly">Polygon</label>
            </li>
        </ul>
    </div>
</ng-template>