<section class="header-padding">
    <div class="container">
        <div class="header-banner-bg position-relative p-0">
            <div class="z-1 position-relative bal-banner">
                <h4 class="">Ethereum Market</h4>
                <div class="d-flex mt-2 mt-lg-5  align-items-center">
                    <div class="amount-details ">
                        <p class="mb-1">Net worth</p>
                        <h3>$ 40</h3>
                    </div>
                    <div class="amount-details ms-5">
                        <p class="mb-1">Net APY</p>
                        <h3>-</h3>
                    </div>
                </div>
                <div class="text-end">
                    <div class="button-primary cursor-pointer" routerLink="/history">
                        View Transactions
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="box-bg mt-4">
                    <h5>Your Supplies</h5>
                    <div class="d-flex mt-4">
                        <div class="amount-details">
                            <p class="mb-0">Balance</p>
                            <h4 class="">0.00</h4>
                        </div>
                        <div class="ms-5 amount-details">
                            <p class="mb-0">Apy</p>
                            <h4 class="">-</h4>
                        </div>
                    </div>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Assets</th>
                                <th scope="col">Wallet Bal</th>
                                <th scope="col">Apy</th>
                                <th scope="col">Can be collateral
                                </th>
                                <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/btc.png" class="img-fluid me-2" width="18" alt="">
                                            <h5 class="mb-0 fw-bold">BTC</h5>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>0.0%</td>
                                    <td>-</td>
                                    <td>
                                        <div class="d-flex">
                                            <div>
                                                <div class="button-primary btn-sm">
                                                    Withdraw
                                                </div>
                                            </div>
                                            <div class="ms-3">
                                                <div class="button-primary btn-sm">
                                                    Swap
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                            </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="box-bg mt-4">
                    <h5>Your Borrows</h5>
                    <div class="d-flex mt-4">
                        <div class="amount-details">
                            <p class="mb-0">Balance</p>
                            <h4 class="">0.00</h4>
                        </div>
                        <div class="ms-5 amount-details">
                            <p class="mb-0">Apy</p>
                            <h4 class="">-</h4>
                        </div>
                    </div>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Assets</th>
                                <th scope="col">Wallet Bal</th>
                                <th scope="col">Apy</th>
                                <th scope="col">Can be collateral
                                </th>
                                <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/btc.png" class="img-fluid me-2" width="18" alt="">
                                            <h5 class="mb-0 fw-bold">BTC</h5>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>0.0%</td>
                                    <td>-</td>
                                    <td>
                                        <div class="d-flex">
                                            <div>
                                                <div class="button-primary btn-sm">
                                                    Repay
                                                </div>
                                            </div>
                                            <div class="ms-3">
                                                <div class="button-primary btn-sm">
                                                    Borrow
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                
                <div class="box-bg mt-4">
                    <h4 class="">Asset to Supplies</h4>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Assets</th>
                                <th scope="col">Wallet Bal</th>
                                <th scope="col">Apy</th>
                                <th scope="col">Can be collateral
                                </th>
                                <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/btc.png" class="img-fluid me-2" width="18" alt="">
                                            <h5 class="mb-0 fw-bold">BTC</h5>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>0.0%</td>
                                    <td>-</td>
                                    <td>
                                        <div class="d-flex">
                                            <div>
                                                <div class="button-primary btn-sm">
                                                    Supply
                                                </div>
                                            </div>
                                            <div class="ms-3">
                                                <div class="button-primary btn-sm">
                                                    ...
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                
                <div class="box-bg mt-4">
                    <h4 class="">Asset to Borrows</h4>
                    <div class="mt-4 table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Assets</th>
                                <th scope="col">Wallet Bal</th>
                                <th scope="col">Apy</th>
                                <th scope="col">Can be collateral
                                </th>
                                <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/btc.png" class="img-fluid me-2" width="18" alt="">
                                            <h5 class="mb-0 fw-bold">BTC</h5>
                                        </div>
                                    </td>
                                    <td>0.00</td>
                                    <td>0.0%</td>
                                    <td>-</td>
                                    <td>
                                        <div class="d-flex">
                                            <div>
                                                <div class="button-primary btn-sm">
                                                    Borrow
                                                </div>
                                            </div>
                                            <div class="ms-3">
                                                <div class="button-primary btn-sm">
                                                    Details
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<img src="assets/images/img.png" width="100%" />
