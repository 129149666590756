import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './common/layout/layout.component';
import { LandingComponent } from './component/landing/landing.component';
import { HistoryComponent } from './component/history/history.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { OpportunityComponent } from './component/opportunity/opportunity.component';
import { LoginComponent } from './common/login/login.component';
import { securityCheckGuardAfter, securityCheckGuardBeforLogin } from './authGuard/security-check.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent,canActivate:[securityCheckGuardBeforLogin]},
  { path: '', component: LayoutComponent, children: [
      { path: '', component: LandingComponent,canActivate:[securityCheckGuardAfter] },
      { path: 'history', component: HistoryComponent,canActivate:[securityCheckGuardAfter] },
      { path: 'welcome', component: WelcomeComponent,canActivate:[securityCheckGuardAfter] },
      { path: 'opportunity', component: OpportunityComponent,canActivate:[securityCheckGuardAfter] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
