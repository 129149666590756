import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { LandingComponent } from './component/landing/landing.component';
import { LayoutComponent } from './common/layout/layout.component';
import { FooterComponent } from './common/footer/footer.component';
import { HistoryComponent } from './component/history/history.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { OneComponent } from './component/one/one.component';
import { HttpClientModule } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OpportunityComponent } from './component/opportunity/opportunity.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginComponent } from './common/login/login.component'; // <-- import the module
import { AlloePermissionService, NotAlloePermissionService } from './authGuard/security-check.guard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingComponent,
    LayoutComponent,
    FooterComponent,
    HistoryComponent,
    WelcomeComponent,
    OneComponent,
    OpportunityComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot({ timeOut: 3000, positionClass: 'toast-top-right', preventDuplicates: true}),
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    NotAlloePermissionService,AlloePermissionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
