import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/server.service';
import { Web3Service } from '../../services/web3.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2'
declare var $: any;
declare const window: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  tokenList: any;
  selectedCoin: any = {};
  ethAddress: any;
  flashaddress: any;

  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;
  @ViewChild('networkDialog') networkDialog: any;
  allData: any;

  constructor(private callApi: ApiService, private web3: Web3Service, private router: Router, private dialog: MatDialog
    , private changeDetectorRef: ChangeDetectorRef) {
    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map((state: any) => state ? this.filterStates(state) : this.tokenList?.slice())
      );
    this.callApi.subscribeEvent().subscribe((res: any) => {
      if (res?.eventName == 'networkChange') {
        this.getTokenListData(res?.eventDetails)
      }
    })
  }


  async ngOnInit() {
    try {
      this.flashaddress = await this.callApi.getWalletAddress()
      this.getTokenListData(sessionStorage?.getItem('chainId'))
    } catch (error) { }

    try {
      if (await this.flashaddress) {
        window.ethereum.on('chainChanged', (chainId: string) => {
          if (chainId == '0x1' || chainId == '0x89' || chainId == '0x38') {
            console.log(chainId);
            this.web3.openMetamask('').then(async (res) => {
              if (await res) {
                this.getTokenListData(sessionStorage?.getItem('chainId'))
              }
            })
            return
          } else {
            this.dialog.open(this.networkDialog, {
              width: 'auto',
              height: 'auto',
              disableClose: true
            })
          }
        });
      }
    } catch (error) {
    }

    try {
      window.ethereum.on('accountsChanged', async (chainId: string) => {
        await this.web3.getAddress()
        this.flashaddress = this.callApi.getWalletAddress()
        this.changeDetectorRef.detectChanges();
      })
    } catch (error) {

    }
  }

  getTokenListData(id: any) {
    this.callApi.getToken(id)?.subscribe((res: any) => {
      console.log(res);
      this.tokenList = res?.data?.destTokens
      this.ethAddress = res?.data?.srcTokens[0]
      this.allData = res?.data
      this.filteredStates = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map((state: any) => state ? this.filterStates(state) : this.tokenList?.slice())
        );
      this.changeDetectorRef.detectChanges();
    })
  }

  selectCoin(data: any) {
    this.selectedCoin = data
    this.selectedCoin.srcDecimal = data.decimals
  }

  submit() {
    this.selectedCoin.srcAddress = this.ethAddress?.address
    this.selectedCoin.srcSymbol = this.ethAddress?.symbol
    this.selectedCoin.destDecimal = this.ethAddress?.decimals
    $('.btn-close').click()
    this.callApi.publishEvent({ eventName: 'coinData', eventDetails: this.selectedCoin })
    this.router.navigate(['/welcome'])
  }

  connect() {
    this.web3.openMetamask('').then((res: any) => {
      this.flashaddress = res
      this.getTokenListData(sessionStorage?.getItem('chainId'))
    })
  }


  filterStates(name: string) {
    return this.tokenList?.filter((state: any) =>
      state?.symbol?.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  async chooseNetwork(id: string) {
    this.web3.openMetamask(id).then((res) => {
      if (res) {
        this.dialog.closeAll()
        this.flashaddress = this.callApi.getWalletAddress()
        this.changeDetectorRef.detectChanges();
      }
    })
  }

  close() {
    this.dialog.closeAll()
  }

  startStopCron(status: any) {
    let title = status == true ? 'Stop' : 'Start';
    Swal.fire({
      title: `<strong>Are you want to ${title} the <u>Cron?</u></strong>`,
      iconColor: '#18e518',
      confirmButtonColor: '#06BE2E',
      denyButtonColor: '#dcdcdc',
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result: any) => {
      if (result?.isConfirmed) {
        this.callApi.cornActions({ status: !status }).subscribe((res: any) => {
          if (res?.status) {
            this.callApi.showSuccess(res?.message)
            this.allData.cronStatus = !status
          } else {
            this.callApi.showError(res?.message)
          }
        })
      }
    });
  }

  logout() {
    Swal.fire({
      title: `<strong>Are you want to Logout this user</strong>`,
      iconColor: '#18e518',
      confirmButtonColor: '#06BE2E',
      denyButtonColor: '#dcdcdc',
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result: any) => {
      if (result?.isConfirmed) {
        this.callApi.logOut().subscribe((res)=>{ })
        sessionStorage.clear()
        this.flashaddress = undefined
        this.callApi.getUserToken()
        this.callApi.getWalletAddress()
        this.router.navigate(['/login'])
        this.callApi.showSuccess('Logout Successfully')
      }
    })

  }

}
